import { S3_PREFIX } from '@shared-submodule/sm-constants'
import Trans from 'next-translate/Trans'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'

import styles from '../style.module.scss'
import FlagTeam from '@assets/images/placeholder/team-placeholder.svg'
import { convertDate, dateCheck, replaceStringToShort } from '@shared-submodule/utils'
import CustomLink from '@shared/components/customLink'
import batIcon from '@assets/images/icon/cricket-bat.svg'
import useStatus from '@shared-submodule/sm-hooks/useStatus'
import { ENUM_CRICKET_MATCH_PAGES } from '@shared-submodule/sm-enum'

const MyImage = dynamic(() => import('@shared/components/myImage'))

function ScoreCard({ card, seriesId }) {
  const { sStatus, sStatusStr, sLiveGameStatus } = useStatus(card)
  const rewriteURLs = useMemo(() => {
    const urls = {}
    card?.oMatchSeos?.oSeo?.forEach((data) => {
      if (data?.sSlug) {
        urls[ENUM_CRICKET_MATCH_PAGES[data.eSubType]] = `/${data?.sSlug}`
      }
    })
    urls.home = `/${card?.oSeo?.sSlug}`
    return urls
  }, [sStatusStr])
  const { t } = useTranslation()

  const handleClick = (value) => {
    if (sStatusStr === 'live') return rewriteURLs?.commentary || rewriteURLs?.home
    else if (sStatusStr === 'scheduled') return rewriteURLs?.home || `/${value?.oSeo?.sSlug}`
    else if (sStatusStr === 'completed' || sStatusStr === 'cancelled') return rewriteURLs?.['full-scorecard'] || rewriteURLs?.home
  }

  const scoreSplit = (score) => {
    if (score?.includes('&')) {
      let newScoreString = ''
      score.split('&').forEach((ele, i) => {
        if (i === 0) {
          const [score] = ele?.split(' (')
          newScoreString += (score + ' &')
        } else {
          newScoreString += ele
        }
      })
      score = newScoreString
    }
    const scoreSplittedData = score?.split(' ')
    if (scoreSplittedData?.length > 0) {
      return (
        <span>
          {
            scoreSplittedData?.map((ele, i) => {
              if (ele.match(/\(|\)/) || ele.includes('&')) {
                // console.log(ele)
                return <span key={ele + i} className='fw-normal'>{ele + ' '}</span>
              } else {
                return <b key={ele + i}>{ele + ' '}</b>
              }
            })
          }
        </span>
      )
    }
  }

  const TeamA = useMemo(() => (
    <div className={`${styles.teamName} d-flex align-items-center`}>
      <div className={`${styles.teamFlag}`}>
        <MyImage
          src={
            card?.oTeamScoreA?.oTeam?.oImg?.sUrl ? `${S3_PREFIX}${card?.oTeamScoreA?.oTeam?.oImg?.sUrl}` : FlagTeam
          }
          placeholderImage={FlagTeam}
          alt={card?.oTeamScoreA?.oTeam?.sAbbr}
          errorImage={FlagTeam}
          width="20"
          height="20"
          layout="responsive"
          sizes="(max-width: 767px) 24px, (max-width: 991px) 24px, (max-width: 1190px) 200px, 24px"
        />
      </div>
      {sStatusStr !== 'scheduled' && sStatusStr !== 'cancelled' ? (
        <div className={`d-flex position-relative ${styles.liveTeam}`}>
          <div>{card?.oTeamScoreA?.oTeam?.sAbbr}</div>
          {
            sStatusStr === 'live' && card?.oTeamScoreA?.sScoresFull?.includes('*') && (
              <div className={styles.batImg}>
                <MyImage src={batIcon} alt='fixtures' layout="responsive" width="5" height="5" />
              </div>
            )
          }
        </div>
      ) : (
        <span>{card?.oTeamScoreA?.oTeam?.sTitle}</span>
      )}
    </div>
  ), [card, sStatusStr])

  const TeamB = useMemo(() => (
    <div className={`${styles.teamName} d-flex align-items-center`}>
      <div className={`${styles.teamFlag}`}>
        <MyImage
          src={
            card?.oTeamScoreB?.oTeam?.oImg?.sUrl ? `${S3_PREFIX}${card?.oTeamScoreB?.oTeam?.oImg?.sUrl}` : FlagTeam
          }
          placeholderImage={FlagTeam}
          alt={card?.oTeamScoreB?.oTeam?.sAbbr}
          errorImage={FlagTeam}
          width="20"
          height="20"
          layout="responsive"
          sizes="(max-width: 767px) 24px, (max-width: 991px) 24px, (max-width: 1190px) 200px, 24px"
        />
      </div>
      {sStatusStr !== 'scheduled' && sStatusStr !== 'cancelled' ? (
        <div className={`d-flex position-relative ${styles.liveTeam}`}>
          <div>{card?.oTeamScoreB?.oTeam?.sAbbr}</div>
          {
            sStatusStr === 'live' && card?.oTeamScoreB?.sScoresFull?.includes('*') && (
              <div className={styles.batImg}>
                <MyImage src={batIcon} alt='fixtures' layout="responsive" width="5" height="5" />
              </div>
            )
          }
        </div>
      ) : (
        <span>{card?.oTeamScoreB?.oTeam?.sTitle}</span>
      )}
    </div>
  ), [card, sStatusStr])

  const TeamAscore = useMemo(() => (
    <p className={sStatusStr === 'completed' && card?.oTeamScoreA?.iTeamId === card?.iWinnerId ? 'text-success' : ''}>
      {card?.nLatestInningNumber === 1 &&
        (card?.oTeamScoreA?.sScoresFull ? (
          <b>
            {scoreSplit(card?.oTeamScoreA?.sScoresFull)}
          </b>
        ) : (
          <b className='light-text'>
            <Trans i18nKey="common:YetToBat" />
          </b>
        ))}
      {card?.nLatestInningNumber > 1 &&
        (card?.oTeamScoreA?.sScoresFull?.includes('*') ? (
          <b>
            {scoreSplit(card?.oTeamScoreA?.sScoresFull)}
          </b>
        ) : (
          <b className='light-text'>
            {scoreSplit(card?.oTeamScoreA?.sScoresFull)}
          </b>
        ))}
    </p>
  ), [card, sStatusStr])

  const TeamBscore = useMemo(() => (
    <p className={sStatusStr === 'completed' && card?.oTeamScoreB?.iTeamId === card?.iWinnerId ? 'text-success' : ''}>
      {card?.nLatestInningNumber === 1 &&
        (card?.oTeamScoreB?.sScoresFull ? (
          <b>
            {scoreSplit(card?.oTeamScoreB?.sScoresFull)}
          </b>
        ) : (
          <b className='light-text'>
            <Trans i18nKey="common:YetToBat" />
          </b>
        ))}
      {card?.nLatestInningNumber > 1 &&
        (card?.oTeamScoreB?.sScoresFull?.includes('*') ? (
          <b>
            {scoreSplit(card?.oTeamScoreB?.sScoresFull)}
          </b>
        ) : (
          <b className='light-text'>
            {scoreSplit(card?.oTeamScoreB?.sScoresFull)}
          </b>
        ))}
    </p>
  ), [card, sStatusStr])

  return (
    <div>
      <div className={`${styles.item} ${seriesId && styles.shortHeight} overflow-hidden`}>
        <div className='position-relative'>
          <CustomLink href={handleClick(card)} passHref prefetch={false}>
            <a className={styles.scoreCardLink}>{card?.sTitle}</a>
          </CustomLink>
          <div className={`${styles.head} text-nowrap overflow-hidden mb-3 ${sStatusStr === 'live' ? styles.liveImg + ' d-flex' : ''}`}>
            <p className={`${sStatusStr === 'live' ? 'd-flex ' + styles.liveP : ''}`}>
              {sStatusStr === 'completed' && (
                <span className={'success-text font-bold'}>
                  {sStatus}{' '}
                </span>
              )}
              {sStatusStr === 'live' && (
                <span className={`danger-text font-bold ${styles.notLive} position-relative`}>
                  <span className={styles.liveDot}></span>
                  <span>
                    {sLiveGameStatus}{' '}
                  </span>
                </span>
              )}
              {sStatusStr === 'scheduled' && (
                <span className={'theme-text font-bold'}>
                  {sStatus}{' '}
                </span>
              )}
              {sStatusStr === 'cancelled' && (
                <span className="danger-text text-capitalize font-bold">
                  {sStatus}{' '}
                </span>
              )}
              <span className={`${styles.dark} ${sStatusStr === 'live' ? 'position-relative ' + styles.t10 : styles.nonLive}`}> {card?.sSubtitle && card?.sSubtitle}</span>{' '}
              {/* {card?.oVenue?.sLocation && '- ' + card?.oVenue?.sLocation} */}
            </p>
            <p className={`${styles.series} text-nowrap overflow-hidden ${sStatusStr === 'live' ? 'position-relative ' + styles.t10 : styles.nonLive}`}> {card?.oSeries?.sTitle}</p>
          </div>
          <div className={`${styles.teams} ${sStatusStr === 'live' ? styles.mtn20 : ''}`}>
            <div className={`${styles.team} d-flex justify-content-between`}>
              {(card?.iBattingTeamId === card?.oTeamScoreB?.iTeamId && sStatusStr === 'live') ? TeamB : TeamA}
              {(card?.iBattingTeamId === card?.oTeamScoreB?.iTeamId && sStatusStr === 'live') ? TeamBscore : TeamAscore}
            </div>
            <div className={`${styles.team} d-flex justify-content-between`}>
              {(card?.iBattingTeamId === card?.oTeamScoreB?.iTeamId && sStatusStr === 'live') ? TeamA : TeamB}
              {/* {TeamB} */}
              {(card?.iBattingTeamId === card?.oTeamScoreB?.iTeamId && sStatusStr === 'live') ? TeamAscore : TeamBscore}
            </div>
          </div>
          <p
            className={`${styles.result} ${sStatusStr === 'live' || sStatusStr === 'cancelled' ? styles.dangerTxt : sStatusStr !== 'completed' ? 'theme-text' : 'success-text'} text-nowrap overflow-hidden`}
          >
            {sStatusStr === 'scheduled' && convertDate(dateCheck(card?.dStartDate))}
            {sStatusStr === 'live' &&
              (replaceStringToShort(card?.oTeamScoreA?.oTeam, card?.oTeamScoreB?.oTeam, card?.sStatusNote) || (
                <Trans i18nKey="common:MatchWillStartSoon" />
              ))}
            {sStatusStr === 'completed' &&
              replaceStringToShort(card?.oTeamScoreA?.oTeam, card?.oTeamScoreB?.oTeam, card?.sStatusNote)}
            {sStatusStr === 'cancelled' &&
              (replaceStringToShort(card?.oTeamScoreA?.oTeam, card?.oTeamScoreB?.oTeam, card?.sStatusNote) || (
                <Trans i18nKey="common:MatchAbandoned" />
              ))}
          </p>
        </div>
        {!seriesId && (
          <div className={`${styles.btnList} d-flex font-bold`}>
            {
              rewriteURLs?.['fixtures-and-results'] && (<div className={`${styles.detailsArrow} d-flex`}>
                <CustomLink href={rewriteURLs?.['fixtures-and-results']} prefetch={false}>
                  <a>
                    {t('common:Fixtures')} <span className={styles.rightArrow}>&gt;</span>
                  </a>
                </CustomLink>
              </div>)
            }
            {
              rewriteURLs?.['points-table'] && (<div className={`${styles.detailsArrow} d-flex`}>
                <CustomLink href={rewriteURLs?.['points-table']} prefetch={false}>
                  <a>
                    {t('common:Standings')} <span className={styles.rightArrow}>&gt;</span>
                  </a>
                </CustomLink>
              </div>)
            }
            {sStatusStr === 'scheduled' && rewriteURLs?.['fantasy-tips'] && (
              <div className={`${styles.detailsArrow} d-flex`}>
                <CustomLink href={rewriteURLs?.['fantasy-tips']} prefetch={false}>
                  <a>
                    {t('common:FantasyTips')} <span className={styles.rightArrow}>&gt;</span>
                  </a>
                </CustomLink>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

ScoreCard.propTypes = {
  card: PropTypes.object,
  handleClick: PropTypes.func,
  seriesId: PropTypes.string
}

export default ScoreCard
