
import useWindowSize from '@shared/hooks/windowSize'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { NextIcon, NextIconSmall, PrevIcon, PrevIconSmall } from '../ctIcons'
import styles from './style.module.scss'

function Slider({
  children,
  gap = 15,
  nav,
  single,
  dots,
  autoplay,
  navTransparent,
  destroyBelow = 0,
  dotsPositionAbsolute,
  setOnScrollSlider,
  noHideArrows,
  customNextArrow,
  customPrevArrow,
  smallArrow = false,
  rightArrowChange,
  leftArrowChange

}) {
  const [btn, setBtn] = useState({ next: nav, prev: false })
  const [items, setItems] = useState([])
  const [active, setActive] = useState(0)
  // const [firstTime, setFirstTime] = useState(0)
  const activeRef = useRef(active)
  const ref = useRef()
  const [width] = useWindowSize()

  // rightArrowChange: PropTypes.any,
  // leftArrowChange

  const arrowIconNext = rightArrowChange || (smallArrow ? <NextIconSmall /> : <NextIcon />)
  const arrowIconPrev = leftArrowChange || (smallArrow ? <PrevIconSmall /> : <PrevIcon />)

  function handleNext() {
    ref.current.scrollLeft += getItemWidth()
    setOnScrollSlider && setOnScrollSlider(true)
  }

  function handlePrev() {
    ref.current.scrollLeft -= getItemWidth()
    setOnScrollSlider && setOnScrollSlider(true)
  }

  function handleScroll(e) {
    if (e?.type === 'scroll') {
      setOnScrollSlider && setOnScrollSlider(true)
    }

    const t = e?.target || ref.current
    if (nav) {
      const scrollPosition = ref.current?.scrollWidth - ref.current.clientWidth
      setBtn({ next: scrollPosition !== t.scrollLeft, prev: t.scrollLeft > 0 })
    }
  }

  function handleDots(i) {
    setActive(i)
    activeRef.current = i
    ref.current.scrollLeft += (getItemWidth() * i) - ref.current.scrollLeft
  }

  const getItemWidth = () => ref?.current?.childNodes[0]?.clientWidth + gap

  useEffect(() => {
    if (!single) {
      const slider = ref.current
      let isDown = false
      let startX
      let scrollLeft

      slider.addEventListener('mousedown', (e) => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 1 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }
  }, [])

  useEffect(() => {
    let intervalId
    if (ref.current) {
      const child = [...ref.current.childNodes]
      setItems(() => [...ref.current.childNodes])
      if (autoplay) {
        intervalId = setInterval(() => {
          handleDots(activeRef.current === (child.length - 1) ? 0 : activeRef.current + 1)
        }, 4000)
      }
      handleScroll()
      // if (firstTime === 1) {
      //   setOnScrollSlider &&setOnScrollSlider(true)
      //   setFirstTime((prev) => prev++)
      // } else {
      //   setFirstTime((prev) => prev++)
      // }
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [children])

  return (
    <div className={`${styles.slider} ${navTransparent && styles.navTransparent} ${destroyBelow > width ? styles.destroy : ''} position-relative`}>
      {
        (width > 767 || noHideArrows) && btn?.prev &&
        <div className={`${styles.arrow} ${styles.prev} ${customPrevArrow}`} onClick={handlePrev}>
          {/* {smallArrow ? <PrevIconSmall /> : <PrevIcon /> } */}
          {arrowIconPrev}
        </div>
      }
      <div
        className={`${styles.inner} slider-track d-flex ${single ? styles.single : styles.multiple} `} style={{ gap: gap }}
        onScroll={handleScroll}
        ref={ref} id="c-slider">
        {children}
      </div>
      {
        dots &&
        <div className={`${styles.dots} ${dotsPositionAbsolute && styles.dotsPositionAbsolute} d-flex justify-content-center`}>
          {
            items?.map((child, i) => {
              return <button className={active === i ? styles.active : ''} onClick={() => handleDots(i)} key={i}>{i}</button>
            })
          }
        </div>
      }
      {
        (width > 767 || noHideArrows) && btn?.next &&
        <div className={`${styles.arrow} ${styles.next} ${customNextArrow}`} onClick={handleNext}>
          {/* {smallArrow ? <NextIconSmall /> : <NextIcon /> } */}
          {arrowIconNext}
        </div>
      }
    </div>
  )
}
Slider.propTypes = {
  children: PropTypes.node,
  gap: PropTypes.number,
  nav: PropTypes.bool,
  single: PropTypes.bool,
  dots: PropTypes.bool,
  navTransparent: PropTypes.bool,
  dotsPositionAbsolute: PropTypes.bool,
  autoplay: PropTypes.bool,
  destroyBelow: PropTypes.number,
  setOnScrollSlider: PropTypes.func,
  noHideArrows: PropTypes.bool,
  customNextArrow: PropTypes.string,
  customPrevArrow: PropTypes.string,
  smallArrow: PropTypes.bool,
  rightArrowChange: PropTypes.any,
  leftArrowChange: PropTypes.any
}
export default Slider
