import React, { useState, useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Button } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'

import styles from './style.module.scss'
// import { MINI_SCORECARD_SUBSCRIPTION } from '@graphql/home/home.subscription'
// import { MINI_SCORECARD, SERIES_MINI_SCORECARD } from '@graphql/home/home.query'
// import { scoreCardNavLoader } from '@shared/libs/allLoader'
import Slider from '@shared/components/slider'

import ScoreCard from './scorecard'
import GlobalEventsContext from '@shared/components/global-events/GlobalEventsContext'
import { MINI_SCORECARD, SERIES_MINI_SCORECARD } from '@graphql/home/home.query'
// import { scoreCardSliderLoader } from '@shared/libs/allLoader'
import { useRouter } from 'next/router'
import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'
import useWindowSize from '@shared/hooks/windowSize'
import sgMedia from '@assets/images/placeholder/SGMEDIA-LOGO.svg'
import MyImage from '@shared/components/myImage'

function ScorecardSlider({ isSeriesTitle, seriesId, data: seriesScoreCard, customNextArrow, customPrevArrow }) {
  const [onScrollSlider, setOnScrollSlider] = useState(false)
  const cardData = useRef(seriesScoreCard || [])
  const [scoreCardData, setScoreCardData] = useState(seriesScoreCard || [])
  const { isLoaded } = useOnMouseAndScroll()
  const [width] = useWindowSize()
  // const MyImage = dynamic(() => import('@shared/components/myImage'))

  const sortByPriority = ({ seriesData }) => {
    const newSeriesArray = seriesScoreCard?.filter((ele, i) => ele?.oSeries?._id)
    const order = newSeriesArray?.map((ele, i) => ele?.nOrder)?.sort()
    const map = new Map()
    order?.forEach((ele, index) => map?.set(ele, index))
    const newData = [...seriesData]
    newData?.sort((ele1, ele2) => map?.get(ele1?.nOrder) - map?.get(ele2?.nOrder))
    return newData
  }

  const [series, setSeries] = useState(() => {
    const seriesArray = []
    const newSeriesData = sortByPriority({ seriesData: seriesScoreCard })
    newSeriesData?.map(series => {
      return (
        !seriesArray?.includes(series?.oSeries?.sSrtTitle || series?.oSeries?.sTitle) &&
        seriesArray?.push(series?.oSeries?.sSrtTitle || series?.oSeries?.sTitle)
      )
    })
    return seriesArray
  })
  const [activeCard, setActiveCard] = useState('all')
  const { stateGlobalEvents, dispatchGlobalEvents } = useContext(GlobalEventsContext)
  const router = useRouter()
  // const [firstTimeCallAPI, setFirstTimeCallAPI] = useState(0)
  // const [firstTimeCardUpdate, setFirstTimeCardUpdate] = useState(0)

  const [getScoreCard, { data }] = useLazyQuery(isSeriesTitle ? MINI_SCORECARD : SERIES_MINI_SCORECARD, {
    variables: { input: { _id: seriesId } },
    fetchPolicy: 'network-only'
  })

  // typeof window !== 'undefined' && useSubscription(MINI_SCORECARD_SUBSCRIPTION, {
  //   onSubscriptionData: ({ subscriptionData }) => {
  //     if (subscriptionData?.data?.fetchMiniScorecardData) {
  //       const newData = subscriptionData?.data?.fetchMiniScorecardData
  //       const ids = newData.map((e) => e?.iMatchId)
  //       cardData.current = cardData?.current?.map((m) => {
  //         if (ids?.includes(m?.iMatchId)) {
  //           return {
  //             ...m,
  //             ...newData?.filter((e) => e?.iMatchId === m?.iMatchId)[0]
  //           }
  //         } else {
  //           return m
  //         }
  //       })
  //       const filterData = activeCard === 'all' ? cardData?.current : cardData?.current?.filter((series) => series?.oSeries?.sSrtTitle === activeCard || series?.oSeries?.sTitle === activeCard)
  //       setScoreCardData(filterData)
  //       updateHomePageArticleScore(cardData?.current)
  //     }
  //   }
  // })
  // const cardDataLength = !seriesId && data?.fetchMiniScorecardData?.length

  const handleSeries = value => {
    if (value === 'all') {
      setScoreCardData(cardData?.current)
      setActiveCard('all')
    } else {
      setScoreCardData(cardData?.current?.filter(series => series?.oSeries?.sSrtTitle === value || series?.oSeries?.sTitle === value))
      setActiveCard(value)
    }
  }

  useEffect(() => {
    handleSeries('all')
  }, [router])

  const setScoreCardDetail = card => {
    // if (!firstTimeCardUpdate) {
    //   setFirstTimeCardUpdate(1)
    //   setScoreCardData(card?.filter((series) => series?.oSeries?.sSrtTitle === activeCard || series?.oSeries?.sTitle === activeCard))
    // } else {
    // }
    if (typeof card !== 'undefined') {
      setScoreCardData(card)
      cardData.current = card
    }
  }

  // useEffect(() => {
  //   if (seriesId) {
  //     setScoreCardDetail(data?.listSeriesScorecard)
  //   } else {
  //     setScoreCardDetail(data?.fetchMiniScorecardData)
  //     updateHomePageArticleScore(data?.fetchMiniScorecardData)
  //   }
  // }, [data])

  // useEffect(() => {
  //   setScoreCardData(seriesScoreCard?.filter((series) => series?.oSeries?.sSrtTitle === activeCard || series?.oSeries?.sTitle === activeCard))
  // }, [seriesScoreCard])

  useEffect(() => {
    if (!seriesId) {
      updateHomePageArticleScore(data?.fetchMiniScorecardData)
    }
    const newData = seriesId ? data?.listSeriesScorecard : data?.fetchMiniScorecardData
    if (newData?.length > 0) {
      setScoreCardDetail(newData)
      const ids = newData?.map(e => e?.iMatchId)
      cardData.current = cardData?.current?.map(m => {
        if (ids?.includes(m?.iMatchId)) {
          return {
            ...m,
            ...newData?.filter(e => e?.iMatchId === m?.iMatchId)[0]
          }
        } else {
          return m
        }
      })
      const filterData =
        activeCard === 'all' ? cardData?.current : cardData?.current?.filter(series => series?.oSeries?.sSrtTitle === activeCard || series?.oSeries?.sTitle === activeCard)
      setScoreCardData(filterData)
      const seriesArray = []
      const newSeriesData = sortByPriority({ seriesData: cardData?.current })
      newSeriesData?.map(series => {
        return (
          !seriesArray?.includes(series?.oSeries?.sSrtTitle || series?.oSeries?.sTitle) &&
          seriesArray?.push(series?.oSeries?.sSrtTitle || series?.oSeries?.sTitle)
        )
      })
      setSeries(seriesArray)
    }
  }, [data])

  useEffect(() => {
    if (isSeriesTitle) {
      getScoreCard()
    } else {
      setScoreCardDetail(seriesScoreCard)
    }
  }, [seriesScoreCard])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isSeriesTitle) {
        getScoreCard()
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  function updateHomePageArticleScore(updatedData) {
    if (isSeriesTitle) {
      dispatchGlobalEvents({
        type: 'HOME_ARTICLE_UPDATE',
        payload: { ...stateGlobalEvents, homeArticle: updatedData }
      })
    }
  }

  return (
    <section className={`${styles.scorecardSlider} scorecard-slider ${seriesId ? '' : 'pt-0'}`}>
      {isSeriesTitle && series?.length !== 0 && (
        <>
           <div className={`${styles.sgMediaContainer} w-full d-md-none d-flex`}>
           <div className={`${styles.sgmedia} d-flex align-items-center font-semi w-full justify-content-center`}>
             <span className={`${styles.powered} overflow-hidden text-nowrap me-2`}>Powered By</span>
             <MyImage src={sgMedia} alt="SG media" width="100px" height="20px" />
           </div>
         </div>
          <div className={`${styles.scorecardNav} mt-3`}>
            <Container className="text-nowrap scroll-list d-flex m-auto align-items-center ">
              {cardData?.current?.length > 0 && (
                <>
                  <Button
                    variant="link"
                    className={activeCard === 'all' && styles.active}
                    onClick={() => handleSeries('all')}>{`ALL MATCHES (${cardData?.current?.length}) ${width > 767 ? '/' : ''}`}</Button>
                </>
              )}
              {series?.map((card, index) => {
                return (
                  <Button
                    variant="link"
                    key={`sNav${index}`}
                    className={activeCard === card && styles.active}
                    onClick={() => handleSeries(card)}>
                    {card}
                  </Button>
                )
              })}
            </Container>
          </div>
          {/* <div className={`${styles.sgmedia} d-flex font-semi mb-2 container`}>
            <span className={`${styles.powered} overflow-hidden text-nowrap me-2`}>Powered By</span>
            <MyImage src={sgMedia} alt="SG media" width="100px" height="20px"/>
          </div> */}
        </>
      )}
      <Container>
        {scoreCardData?.length > 0 && (
          <Slider
            customNextArrow={customNextArrow}
            customPrevArrow={customPrevArrow}
            nav
            gap={12}
            destroyBelow={2000}
            setOnScrollSlider={setOnScrollSlider}>
            {scoreCardData?.map((card, index) => {
              if (card?.oSeries?._id) {
                if (index < 4) {
                  return <ScoreCard card={card} key={card?.iMatchId + '_' + index} seriesId={seriesId} />
                } else {
                  return onScrollSlider || isLoaded ? (
                    <ScoreCard card={card} key={card?.iMatchId + '_' + index} seriesId={seriesId} />
                  ) : null
                }
              } else {
                return <></>
              }
            })}
          </Slider>
        )}
        {/* {isSeriesTitle && loading && scoreCardNavLoader()} */}
        {/* {loading && scoreCardSliderLoader()} */}
      </Container>
    </section>
  )
}

ScorecardSlider.propTypes = {
  isSeriesTitle: PropTypes.bool,
  seriesId: PropTypes.string,
  data: PropTypes.array,
  customNextArrow: PropTypes.any,
  customPrevArrow: PropTypes.any
}

export default ScorecardSlider
